<template lang="">
  <div class="flex justify-center items-center relative" v-click-outside="closeDropdown">
    <button type="button" class="btn-listen" @click="isOpen = !isOpen"><i class="icon-img ic-play-button-small"></i><span class="for-a11y">미리듣기</span></button>

    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    <div v-if="isOpen" class="absolute top-6 right-1 py-2 mt-2 rounded-lg bg-sm-dark-indigo shadow-xl z-20 min-w-max px-5">
      <div class="bg-sm-dark-indigo py-2">
        <audio ref="preview" controls="true" autoplay="true" :src="audioFile"></audio>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { requestApi } from "@/utils/api"

export default {
  props: {
    fileName: String
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      isOpen: false,
      audioFile: null,
    }
  },
  methods: {
    closeDropdown () {
      this.isOpen = false
    },
    async getSoundData () {
      let reqObj = {}
      reqObj.target = `/files/sound/${this.fileName}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      // this.audioFile = "data:audio/wav;base64," + result.msg.retData;
      this.audioFile = result.msg.retData;
    },
  },
  watch: {
    'isOpen': function () {
      if(this.isOpen) this.getSoundData ()
    }
  }
}
</script>
<style lang="">
</style>